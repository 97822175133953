<template>
  <div>
    <v-container>
      <base-header>
        <div slot="page-header">
          <div class="d-flex align-center">
            <back-button></back-button>
            <span class="text-h6">{{ name }}</span>
          </div>
        </div>
      </base-header>
      <v-form v-model="valid" @submit.prevent="onSubmit">
        <v-row>
          <v-col
              cols="12"
          >
            <v-img :src="avatarUrl" v-if="avatarUrl" style="width:60px;height:60px;object-fit: contain;"/>
            <v-file-input
                :rules="avatarRules"
                accept="image/png, image/jpeg, image/gif"
                placeholder="Pick an avatar"
                prepend-icon="mdi-camera"
                label="Avatar"
                v-model="avatarFile"
            ></v-file-input>
          </v-col>

          <v-col
              cols="6"
          >
            <v-text-field
                v-model="name"
                :rules="nameRules"
                label="Name"
                required
            ></v-text-field>
          </v-col>

          <v-col
              cols="6"
          >
            <v-text-field
                v-model="symbol"
                :rules="symbolRules"
                label="Symbol"
                required
            ></v-text-field>
          </v-col>

          <v-col
              cols="6"
          >
            <v-text-field
                v-model="address"
                :rules="symbolRules"
                label="Address"
                required
            ></v-text-field>
          </v-col>

          <v-col
              cols="6"
          >
            <v-select
                v-model="chain"
                :items="supportedChains"
                :rules="[v => !!v || 'Chain is required']"
                label="Chain"
                required
            ></v-select>
          </v-col>

          <v-col
              cols="12"
          >
            <v-textarea
                v-model="description"
                label="Description"
            ></v-textarea>
          </v-col>

          <v-col
              cols="12"
          >
            <v-btn
                class="mr-4"
                type="submit"
            >
              save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>

</template>

<script>
import BaseHeader from "../../components/Base/BaseHeader.vue";
import BackButton from "@/components/Common/BackButton";
import axios from "axios";

export default {
  name: "TokenContract",
  components: {
    BackButton,
    BaseHeader,
  },
  methods: {
    fetchTokenContract(tokenContractId) {
      this.loading = true;
      axios.get(process.env.VUE_APP_BASE_API_URL + `/admin/nft-contract/${tokenContractId}`).then(resp => {
        this.tokenContract = resp.data;
        this.name = this.tokenContract.name;
        this.symbol = this.tokenContract.symbol;
        this.description = this.tokenContract.description;
        this.address = this.tokenContract.address;
        this.chain = this.tokenContract.chain;
        this.avatarUrl = this.tokenContract.avatarUrl;
      }).finally(() => {
        this.loading = false;
      })
    },
    onSubmit() {
      this.loading = true;
      let url = process.env.VUE_APP_BASE_API_URL + '/admin/nft-contract/' + this.tokenContract.id;
      const sendFormData = new FormData();
      if (this.avatarFile) {
        sendFormData.append('avatar', this.avatarFile);
      }
      sendFormData.append('symbol', this.symbol)
      sendFormData.append('name', this.name)
      sendFormData.append('description', this.description || "")
      sendFormData.append('address', this.address)
      sendFormData.append('chain', this.chain)

      axios.put(url, sendFormData).then(resp => {
        this.$toast.success('Contract updated')
        this.$router.back()
      }).catch(() => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path !== from.path) {
      this.fetchTokenContract(to.params.tokenContractId)
    }
    next()
  },
  created() {
    this.fetchTokenContract(this.$route.params.tokenContractId)
  },
  data() {
    return {
      loading: false,
      valid: false,
      name: '',
      chain: '',
      symbol: '',
      address: '',
      description: '',
      avatarUrl: null,
      avatarFile: null,
      avatarRules: [
        value => !value || value.size < 20000000 || 'Avatar size should be less than 20 MB!',
      ],
      supportedChains: [
        'BNB',
        'ETH',
        'MATIC',
        'KLAY',
      ],
      symbolRules: [
        v => !!v || 'Symbol is required',
      ],
      nameRules: [
        v => !!v || 'Name is required',
      ],
    }
  }
}
</script>

<style scoped>

</style>
